import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { Icons, Images } from "../../assets/assets";
import Footer from "../../components/Footer/Footer";
import WaterMakerCard from "../../components/WaterMakerCard/WaterMakerCard";
import SecondaryButton from "../../components/Button/SecondaryButton";
import { Link } from "react-router-dom";
import { WaterMachine } from "../../@types/productInfo";
import waterMachineServiceInstance from "../../APIs/watermachines";
import { Helmet } from "react-helmet";
import PrimaryButton from "../../components/Button/PrimaryButton";
import RetailerModal from "../../components/RetailerModal/RetailerModal";


export const RoundedCircleComponent = ({ bgColor }: { bgColor: string }) => (
  <div
    className="w-6 h-6 rounded-full flex items-center justify-center"
    style={{
      backgroundColor: "#FFFFFF",
      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.29)",
      opacity: 1,
    }}
  >
    <div
      className={`w-4 h-4 rounded-full bg-${bgColor} border border-gray-200`}
    />
  </div>
);

const SparklingWaterMaker = () => {
  const [terraColor, setTerraColor] = React.useState("black");
  const [artColor, setArtColor] = React.useState("black");

  const [displayData, setDisplayData] = useState<WaterMachine[]>([]);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response =
        await waterMachineServiceInstance.getAllWaterMachines();
      if (response.data) {
        setDisplayData(response.data);
      } else {
        console.error("error :", response.error);
      }
    } catch (error) {}
  };

  const capitalizeFirstLetter = (str: string) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  function toUrlSlug(text: string) {
    const decoded = decodeURIComponent(text);
    const hyphenated = decoded.replace(/\s+/g, "-");
    return hyphenated;
  }







  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {
            "Home Sparkling Water Machine - Sparkling Water Maker | SodaStream SA"
          }
        </title>
        <meta
          name="description"
          content={
            "Make your own sparkling water in the comfort of your home with SodaStream sparkling water makers. Preparing a bubbly soda drink has never been easier."
          }
        />
        <link
          rel="canonical"
          href="https://www.sodastream.co.za/water-makers"
        />
        <meta
          name="keywords"
          content="SodaStream, Home Water Carbonation System, South Africa, Sparkling water machines, makers, bottles, flavours"
        />

        <meta name="avg-search-volume" content="40" />

        <meta name="search-intent" content="Commercial" />
      </Helmet>
      <Header
        title="Sparkling Water Makers"
        backgroundImg={"http://res.cloudinary.com/brabenjos/image/upload/v1723712531/sodastream/1723712530881.png"}
        textBackground="WATER MAKERS"
      />

      <section>
        <div className="relative min-h-[15rem] w-full">
          <div className="md:relative gap-10 z-50 flex flex-col items-center justify-center w-full">
            <div className="mt-[-16%]">
              <div className="grid md:grid-cols-2 grid-cols-1 gap-10 md:px-96 px-10">
                {/* marks */}
                {displayData.map((data, index) => {
                  const arrayblack = data.images_black[0];
                  const arraywhite = data.images_white[0];
                  return (
                    <WaterMakerCard key={index}>
                      <div className="flex flex-col gap-4 py-5 items-center">
                        <img
                          src={terraColor === "white" ? arraywhite : arrayblack}
                          alt={data.name + " Sparkling Water Maker"}
                          style={{ width: "22%" }}
                        />
                        <span className="text-[#005D6E] text-bold text-[20px]">
                          {capitalizeFirstLetter(data.name)}
                        </span>
                       
                        <span className="text-[#005D6E] text-[10px] text-center px-10">
                          Push, fizz, enjoy fresh sparkling water in seconds.
                          Sparkle your water at the push of a button.
                        </span>
                        {/* <span>⭐️⭐️⭐️⭐️⭐️</span> */}
                        <div className="flex gap-2 justify-center">
                          <img
                            src={Icons.IconBottle}
                            alt="bottle"
                            style={{ width: "10%", height: "10%" }}
                          />
                          <img
                            src={Icons.IconCordless}
                            alt="bottle"
                            style={{ width: "10%", height: "10%" }}
                          />
                          <img
                            src={Icons.Icondishwasher}
                            alt="bottle"
                            style={{ width: "10%", height: "10%" }}
                          />
                          <img
                            src={Icons.IconEgronomicButton}
                            alt="bottle"
                            style={{ width: "10%", height: "10%" }}
                          />
                          <img
                            src={Icons.IconPatentedButton}
                            alt="bottle"
                            style={{ width: "10%", height: "10%" }}
                          />
                        </div>
                        <div className="flex gap-2 justify-center border-y border-gray-300 py-2 mt-2 items-center">
                          <img
                            src={Images.BottleClassic1L}
                            alt="bottle"
                            className="h-5 w-3"
                          />
                          <span className="font-semibold text-[#005D6E] text-[12px]">
                            1L Reusable Plastic Bottle
                          </span>
                        </div>
                        <div className="flex gap-2 justify-center">
                          <div
                            onClick={(e) => {
                              setTerraColor("black");
                            }}
                          >
                            <RoundedCircleComponent bgColor="black" />
                          </div>

                          <div onClick={() => setTerraColor("white")}>
                            <RoundedCircleComponent bgColor="white" />
                          </div>
                        </div>

                        <div className="w-full flex items-center  justify-center space-x-2 ">
                        <Link to={toUrlSlug(data.machinelink)}>
                          <SecondaryButton label="VIEW PRODUCT" />
                          </Link>
                         
                          </div>
                      </div>
                    </WaterMakerCard>
                  );
                })} 
              </div>
            </div>
          </div>
        </div>
        {/* out of absolute position */}
        <div className="grid grid-cols-2 md:grid-cols-5 md:flex-nowrap md:px-20 md:py-20 p-5">
          <div className="flex flex-col gap-2 items-center justify-center basis-[18%]">
            <img src={Icons.IconSustainability} alt="Reducing single-use plastic icon" />
            <span className="text-[16px] text-[#005D6E] text-center px-6">
              Live sustainably by reducing single-use plastic.
            </span>
          </div>

          <div className="flex flex-col gap-2 items-center justify-center basis-[18%]">
            <img src={Icons.IconFreshWater} alt="Fresh sparkling water icon" />
            <span className="text-[16px] text-[#005D6E] text-center px-6">
              Enjoy fresh sparkling water just the way you like it.
            </span>
          </div>

          <div className="flex flex-col gap-2 items-center justify-center basis-[18%]">
            <img src={Icons.IconGoodByeCarrying} alt="Goodbye to carrying heavy plastic bottles" />
            <span className="text-[16px] text-[#005D6E] text-center px-6">
              Say goodbye to carrying heavy plastic bottles.
            </span>
          </div>

          <div className="flex flex-col gap-2 items-center justify-center basis-[18%]">
            <img src={Icons.IconHeathier} alt="Create healthy soft drinks" />
            <span className="text-[16px] text-[#005D6E] text-center px-6">
              Create healthy, delicious soft drinks, cocktails and more.
            </span>
          </div>

          <div className="flex flex-col gap-2 items-center justify-center basis-[18%]">
            <img src={Icons.IconDishWasherSDS} alt="Dishwasher safe" />
            <span className="text-[16px] text-[#005D6E] text-center px-6">
              Dishwasher safe
            </span>
          </div>
        </div>
      </section>

      {/*  */}
      <section>
        <div className="flex md:flex-nowrap flex-wrap gap-0 text-white">
          <div>
            <img
              src={Images.PouringFromFuseBottle}
              alt="bg"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="flex flex-col gap-5 md:px-20 px-10 py-10 bg-[#005D6E] text-start">
            <h2 className="text-[24px] font-semibold">
              Sparkling Water Makers
            </h2>
            <div className="flex flex-col gap-3 font-light">
              <p>
                SodaStream offers a full range of sparkling water makers,
                delivering fresh bubbles to your water with a push of a button!
              </p>
              <p>
                {" "}
                SodaStream water carbonators turn still water into sparkling
                water in seconds. We offer the best assortment of sparkling
                water machines for your home, including the new Terra with an
                easy-to-connect CO2 cylinder and dishwasher-safe bottle.
              </p>
              <p>
                {" "}
                If you are looking for the best sparkling water machine that
                allows you to customise your fizz from the convenience of your
                home, SodaStream is for you.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/*  */}
      <Footer />
    </div>
  );
};

//

export default SparklingWaterMaker;