import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Icons, Images } from "../../assets/assets";
import "./styles.css";
import { Helmet } from "react-helmet";
const TermsCompetition = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"Competition Terms | SodaStream SA"}</title>
        <meta
          name="description"
          content={
            "Familiarise yourself with the terms and conditions of SodaStream competitions. Join us to win prizes!"
          }
        />
         <link rel="canonical" href="https://www.sodastream.co.za/competition-terms" />
      </Helmet>
      <Header
        title="Terms & Conditions"
        subTitle=""
        textBackground="Ts & Cs"
        backgroundImg={Images.Page27BG}
      />
      <div className="relative min-h-[auto] w-full">
        <div className="relative md:top-[-9rem] top-0 gap-10 md:z-50 z-9 
        flex flex-col items-center justify-center  w-full">
          <div className="mt-5 lg:px-30 md:px-15 sm:px-20 px-5 w-full">
            <div className="bg-white rounded-xl border border-gray-300 relative w-full opacity-80 px-10 py-20 text-[#005D6E]">
              <div className="flex flex-col gap-4 text-[12px] text-start">
                <h1 className="text-4xl font-semibold">SodaStream SA UCL Competition</h1>
                <h2 className="text-lg font-semibold">
                Open for entries between 1 March to 30 April 2025
                </h2>
                <ul>
                
                  Join the excitement with SodaStream SA and stand a chance to win exclusive prizes in our UEFA Champions League Competition! From 1 March to 30 April 2025, purchase a SodaStream Terra Machine, Art Machine, or a 60L SodaStream Cylinder (including refills) at participating retailers to enter. Amazing prizes are up for grabs. For full terms and conditions, including eligibility, prize details,
                   and entry requirements, pleasen 
                   <a download target="blank" href="http://res.cloudinary.com/brabenjos/image/upload/v1740563262/sodastream/1740563261807.pdf" ><b> click here to download the T&Cs (PDF)</b>. </a>{" "}
              
                
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsCompetition;
