import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

interface SliderProps {
  images: string[];
}

const SliderHome: React.FC<SliderProps> = ({ images }) => {
  return (
    <div className="relative">
      <Carousel
        showThumbs={false}
        infiniteLoop={true}
        emulateTouch={true}
        swipeable={true}
        showStatus={false}
        renderArrowPrev={(
          onClickHandler: () => void,
          hasPrev: boolean,
          label: string
        ) =>
          hasPrev && (
            <button
              type="button"
              onClick={onClickHandler}
              title={label}
              className="hidden md:absolute left-0 top-1/2 transform -translate-y-1/2 text-white z-50">
              <BsChevronLeft fontSize={50} />
            </button>
          )
        }
        renderArrowNext={(
          onClickHandler: () => void,
          hasNext: boolean,
          label: string
        ) =>
          hasNext && (
            <button
              type="button"
              onClick={onClickHandler}
              title={label}
              className="hidden md:absolute right-0 top-1/2 transform -translate-y-1/2 text-white z-50">
              <BsChevronRight fontSize={50} />
            </button>
          )
        }>
        {images.map((image, index) => (
          <div key={index} className="w-full h-full">
            <img
              className="h-[550px] sm:h-full"
              src={image}
              alt={`Slide ${index + 1}`}
              width="100%"
              height="100%"
            />
          </div>
        ))}
      </Carousel>

      <div className="absolute left-8 bottom-1/4 z-10 sm:ml-[150px] ml-[100px]">
        <a
          href="https://wkf.ms/4bUrD6g"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block bg-white hover:bg-[#75A7AD] text-[#75A7AD]
     hover:text-white font-bold py-2.5 px-5 rounded-full shadow-lg transition duration-300 text-lg active:bg-[#75A7AD] active:text-white">
          ENTER NOW
        </a>
      </div>
    </div>
  );
};

export default SliderHome;
