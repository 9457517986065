import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import { Images } from "../../assets/assets";
import SecondaryButton from "../../components/Button/SecondaryButton";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Navbar from "../../components/Navbar/Navbar";
import { ProductService } from "../../APIs";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import Header from "../../components/Header/Header";
import RetailerModal from "../../components/RetailerModal/RetailerModal";
//TODO: Create types folder and export them

export interface Products {
  id?: number;
  banner: string;
  name: string;
  description: string;
  meta_description: string;
  meta_title: string;
  caption: string;
  ingredient: string;
  price: string | number;
  rating: string | number;
  image: string;
  category: string;
  cta?: string;
  productlinkname: string;
  productaltbanner: string;
}

const ProductPage = (props: any) => {
  const [selectedProduct, setSelectedProduct] = useState<Products | null>(null);
  const { productlinkname } = useParams<{ productlinkname: string }>();

  const encodedUrl = productlinkname ? productlinkname.replace(/-/g, " ") : "";
  
  const capitalizeFirstLetter = (str: string) => {
    if (!str) return ''; 
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const fetchProductById = async (productlinkname: string) => {
    try {
      const { data, error } = await ProductService.getProductByLinkName(productlinkname);
      if (data) {
        setSelectedProduct(data);
      } else {
        console.error("Error fetching product by ID:", error);
      }
    } catch (error) {
      console.error("Error fetching product by ID:", error);
    }
  };

  useEffect(() => {
    if (encodedUrl) {
      fetchProductById(encodedUrl);
    }
  }, [encodedUrl]);

  const breadcrumbItems = [
    { text: "HOME", href: "/" },
    { text: "FLAVOURS", href: "/flavours" },
    { text: selectedProduct ? selectedProduct.name : "Loading..." },
  ];

  const toUrlSlug = (text: string) => {
    const decoded = decodeURIComponent(text);
    const hyphenated = decoded.replace(/\s+/g, "-");
    return hyphenated;
  };

  return (
    <div>
      {selectedProduct ? (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{selectedProduct?.meta_title}</title>
            <meta
              name="description"
              content={selectedProduct?.meta_description}
            />
            <link rel="canonical" href={`https://www.sodastream.co.za/${toUrlSlug(selectedProduct.productlinkname)}`} />
            <meta
              name="keywords"
              content="home water carbonation system, home water carbonation system south africa, sparkling water machine for home"
            />
            <meta name="avg-search-volume" content="10 10 40" />
            <meta name="search-intent" content="Informational, Commercial, Commercial" />
          </Helmet>

          <Header
            title={capitalizeFirstLetter(selectedProduct.name)}
            textBackground="Flavours"
          />
          <section>
            <div className="bg-[#FFFFFF] text-start px-20">
              <Breadcrumb items={breadcrumbItems} />
            </div>
            <div className="flex items-center px-20 py-5 gap-5 bg-[#FFFFFF] mb-5 md:flex-row md:flex-nowrap flex-col flex-wrap">
              <div className="basis-[30%] flex flex-col items-center justify-center">
                <img
                className="w-[270px] mw-[200px]"
                  src={selectedProduct.image}
                  alt={capitalizeFirstLetter(selectedProduct.productlinkname)}
                />
              </div>
              <div className="flex-grow flex flex-col items-center text-start">
                <div className="flex flex-col gap-4 text-[#005D6E]">
                  <h2 className="text-[#005D6E] text-[24px]">
                    {capitalizeFirstLetter(selectedProduct.name)}
                  </h2>
                  <p>{selectedProduct.description}</p>
                  <section  className="flex md:flex-row flex-col gap-4">

                  <div className="mr-2">
                    <Link to="/store-locator">
                      <SecondaryButton label={"Store Locator"} />
                    </Link>
                  </div>
                  <div>
                    <RetailerModal/>
                  </div>
                  </section>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="flex gap-0 flex-col md:flex-row">
              <div className="basis-[50%] p-20 bg-[#EEEAE5] flex items-center justify-center">
                <p className="text-[#005D6E] font-bold">
                  {selectedProduct.caption}
                </p>
              </div>
              <div className="basis-[50%]">
                <img src={selectedProduct.banner} alt={capitalizeFirstLetter(selectedProduct.productaltbanner)} />
              </div>
            </div>
          </section>

          <section>
            <div className="flex gap-0 flex-col-reverse md:flex-row ">
              <div className="basis-[50%]">
                <img src={Images.FuseBottleWorkingWomen} alt="bg" />
              </div>
              <div className="basis-[50%] p-20 bg-[#EEEAE5] flex flex-col items-start justify-center gap-6">
                <h2 className="text-[#005D6E] font-bold text-[20px]">
                  What's inside?
                
                </h2>
                <p className="text-[#005D6E]">{selectedProduct.ingredient}</p>
              </div>
            </div>
          </section>
          <Footer />
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default ProductPage;
